import React from 'react';
import relicsMap from './images/relics.gif'

const Relics = () => {
    return (
        <div className="relics" style={styles.relics}>
            <img src={relicsMap} style={styles.map} alt="Relics Map" />
        </div>
    );
};

const styles = {
    relics: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    map: {
        maxWidth: '80%',
        height: 'auto',
    }
}

export default Relics;
