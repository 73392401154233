import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [currentPage, setCurrentPage] = useState(location.pathname);

    const handleLinkClick = (page) => {

        if (page === 'kickstarter') {
            window.open('https://www.kickstarter.com/projects/jenkins69/relic-arena', '_blank', 'noopener,noreferrer');
        } else {
            if (page === 'home') {
                navigate('/');
            } else {
                navigate('/' + page);
            }
            setCurrentPage(page);
        }
    };

    return (
        <div className="navbar">
            {['home', 'socials', 'faq', 'relics', 'leaders', 'map', 'kickstarter'].map((page) => (
                <span
                    key={page}
                    className={currentPage === page ? 'active' : ''}
                    onClick={() => handleLinkClick(page)}
                >
                    <span className="link-text">
                        {page === 'faq' ? page.toUpperCase() : page.charAt(0).toUpperCase() + page.slice(1)}
                    </span>
                </span>
            ))}
        </div>
    );
};

export default Navbar;
