import React from 'react';
import DiscordLogo from './images/discord-logo-white.png';
import './discord-join.css';

const DiscordJoin = () => {
    const joinLink = 'https://discord.gg/uVN64WHzxp';
    return (
        <div className='discord-logo'>
            <a href={joinLink}><img src={DiscordLogo} alt="Join Discord" /></a>
        </div>
    );
};

export default DiscordJoin;
