import React from 'react';
import './steam-wishlist.css';

const SteamWishlist = () => {
    return (
        <div className='steam-wishlist'>
            <iframe src="https://store.steampowered.com/widget/2654490/?t=Relic%20Arena%2C%20a%20free-to-play%208-player%20autobattler%20for%20PC%20and%20Mobile%20by%20Dota%202%20personalities%20Jenkins%20and%20SUNSfan%2C%20combines%20historical%20fiction%20with%20strategic%20gameplay.%20Players%20equip%20heroes%20with%20genuine%20historical%20relics%2C%20each%20providing%20unique%20abilities%20and%20upgrades%2C%20under%20the%20guidance%20of%20renowned%20historical%20figures." frameBorder="0" width="646" height="190"></iframe>
        </div>
    );
};

export default SteamWishlist;
