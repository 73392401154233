import React from 'react';
import './faq.css'

const Faq = () => {
    return (
        <div className='faq'>
            <h1>What type of game is Relic Arena?</h1>
            <h4>Relic Arena is an Auto-battler that is being created by Double Edge Games, run by <a href="https://liquipedia.net/dota2/SUNSfan">SUNSfan</a> & <a href="https://liquipedia.net/dota2/Jenkins">Jenkins</a>. It is based on their Dota 2 custom game Ability Arena.</h4>
            <h1>What platforms will this be available on?</h1>
            <h4>PC & Mobile. You’ll be able to play on PC by downloading through STEAM or on mobile through the Google or Apple store. <a href="https://store.steampowered.com/app/2654490/Relic_Arena">Be sure to Steam Wishlist the game.</a></h4>
            <h1>What's the lore?</h1>
            <h4>Old Man Gilly Winkle was formerly a renowned museum curator that turned recluse after stumbling across a relic unlike any other, the Eon Shard. This artifact enabled him to create a game of sorts that inexplicably revives famous figures from human history, allowing them to control armies in a free for all battle.</h4>
            <h1>What are Relics?</h1>
            <h4>Relics are spells, which are based off of real life artifacts in history. They are purchased every round in a shop and placed on hero units, which then physically show up on the hero model. The more duplicates you find, the stronger the Relic becomes. Once you pass a certain threshold, it becomes a Super Relic, which allows you to choose from one of 3 upgrades. The next and final threshold is the Juicy Relic, which transforms the spell into something powerful and sometimes ridiculous.</h4>
            <h1>What's a Leader?</h1>
            <h4>Leaders are characters you choose at the beginning of the game that provide some sort of passive buff towards your units. Leaders will incentivize you to play in different ways, which will keep the game feeling fresh. These characters will be based off of real life figures in history.</h4>
            <h1>Will it be free to play?</h1>
            <h4>YES! Relic Arena will be completely free to play.</h4>
            <h1>Will there be Pay-to-Win mechanics?</h1>
            <h4>Absolutely not! Most of our monetization will revolve around cosmetics, while the rest will be pay for convenience.</h4>
            <h1>Do you have a release date?</h1>
            <h4>We don’t have a set release date yet, but we’re hoping the game will be done by March 2025. There will be ways to play the Closed Alpha and Closed Beta via our Kickstarter. We’re also hoping the Alpha will be playable in March 2024, while the Beta is currently slated for September 2024.</h4>
            <h1>Do I need an internet connection to play?</h1>
            <h4>No, as there will be ways to play against Bots. If you want to play against people then you will need the internet.</h4>
            <h1>How many people can play Relic Arena?</h1>
            <h4>Games consist of 8 total players.</h4>
            <h1>Are there classes in the game?</h1>
            <h4>We do not have classes in the traditional auto-battler sense. The closest we have are  the Hero bodies you select, which come with various different stats that allow you to use them for different functions.</h4>
            <h1>Will the Kickstarter rewards ever be available again?</h1>
            <h4>If they say exclusive, they will NOT be available in the future.</h4>
            <h1>Is there an economy like in Auto Chess or TFT?</h1>
            <h4>The short answer is no, as gold will reset every round. However, there may be a Leader that specializes in saving gold with interest. There will also be a mechanic for toilet players out there, where if you aren't able to spend your gold in time, you'll collect magic dust which will allow you to recoup some of the lost economy.</h4>
            <h1>How many heroes do each team get and how many Relics can a hero equip?</h1>
            <h4>We have a number in mind, but we don't want to reveal it as things like this will be very fluid based on alpha testing. We are not afraid to make big changes to the game if we deem them necessary.</h4>
            <h1>I have a question that is not listed here, help!</h1>
            <h4><a href="https://discord.gg/uVN64WHzxp">Join our Discord!</a></h4>
        </div>
    );
};

export default Faq;
