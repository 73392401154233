import React from 'react';
import './video.css';

const Video = ({ videoId }) => {
    const src = `https://www.youtube.com/embed/${videoId}`;
    return (
        <div className="video-responsive">
            <iframe
                src={src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default Video;
