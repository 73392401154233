import React from 'react';
import './press-release.css';
import Promo from './images/press-promo.jpg';
import Rocket from './images/press-rocket.mp4';
import Leaders from './images/press-leaders.png';
import Moon from './images/press-moon.jpg';
import Battle from './images/press-battle.jpg';

const PressRelease = () => {
    return (
        <div className="press-release">
            <img src={Promo} alt="Promotional Art"></img>
            <h1> Relic Arena Kickstarter Launched: Revolutionizing the Auto Battler Genre with "Build Your Own Hero" Experience</h1>
            <h4>[Phoenix, AZ], [12/4/23] - <a href="https://liquipedia.net/dota2/SUNSfan">Shannon ‘SUNSfan’ Scotten</a> and <a href="https://liquipedia.net/dota2/Jenkins">Andrew ‘Jenkins’ Jenkins</a>, renowned Dota 2 commentators and game development enthusiasts, proudly announce the launch of the Kickstarter campaign for Relic Arena, which is a standalone version of their popular arcade game. This innovative, free-to-play auto battler, coming to both PC and Mobile platforms, offers a fresh twist on the genre with its unique "build your own hero" concept.
            <br></br><br></br>
            As popular Dota 2 personalities, they created one of the most celebrated custom games in the community. However, due to Valve unexpectedly putting an end to monetization on the platform entirely, they have now embarked on a journey to develop a standalone version of the game.</h4>
            <h1>Redefining Auto Battlers</h1>
            <video width="680" height="383" loop="loop" autoplay="autoplay" muted="muted">
              <source src={Rocket} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
            <br></br>
            Animated GIF showing the F1-Rocket Engine (PRE ALPHA FOOTAGE)
            <h4>Unlike traditional auto battlers that focus on buying units with pre-defined synergies, Relic Arena empowers players to craft their heroes. Through a rich selection of real-life historical relics, each with unique abilities and significant historical context, players strategically build and upgrade their units, ensuring no two heroes are the same.</h4>
            <h1>A New Twist on an Old Favorite</h1>
            <h4>One of the game’s standout features is the ability to purchase duplicate relics, progressively amplifying their power. Players face strategic decisions leading to three powerful branching upgrades and ultimately, a 'juicy upgrade' - a max-level ultimate upgrade with game-changing effects, capable of turning the tide of battle.</h4>
            <h1>Historical Leaders and Diverse Strategies</h1>
            <h4>Players commence each match by choosing from a roster of historical leaders, each offering unique passive buffs that incentivize players to play each game differently. The initial lineup includes Alan Turing, Abraham Lincoln, Cleopatra, and Mahatma Gandhi, with more to be revealed.</h4>
            <img src={Leaders} alt="Leaders"></img>
            <br></br>
            4 Leaders have been revealed, with more to come via stretch and social goals
            <h1>Casual and Competitive</h1>
            <h4>Leveraging SUNSfan and Jenkins' extensive experience playing and commentating professional esports, Relic Arena is meticulously crafted to thrive in a competitive landscape. They understand the nuances of what makes a game both exhilarating for esports athletes and engaging for the audience.
            <br></br><br></br>
            Yet, there is a firm belief in inclusivity—the game is designed to be as welcoming to casual players as it is challenging for hardcore gamers. By focusing development on both PC and mobile, Relic Arena is looking to create a game that is both welcoming to new fans of the genre, and rewarding for its veterans.
            <br></br><br></br>
            Shannon 'SUNSfan' Scotten had the following to say on the matter: "We take pride in our somewhat cheeky motto: 'This game is casual enough to play a game while on the toilet, yet hardcore enough to play on stage at a LAN in front of 10,000 people. Just don't do both at the same time.' This encapsulates our vision: a game that fits into your daily routine with ease, but also boasts the depth and complexity to take center stage in competitive gaming arenas."</h4>
            <img src={Moon} alt="Moon crashing down onto the arena"></img>
            <br></br>
            The Juicy upgrade for the F1-Rocket Engine brings the Moon crashing down onto the arena (PRE ALPHA FOOTAGE)
            <h1>Player-Friendly Monetization and Cosmetics</h1>
            <h4>The free-to-play monetization will not include any 'GACHA' or 'PAY-TO-WIN' mechanics. There will be a Battle Pass system, prioritizing player satisfaction with exclusive cosmetics and leader unlocks. Additional customization options include unique visual effects, sound packs, and board designs.</h4>
            <h1>The Team Behind the Vision</h1>
            <h4>Double Edge Studios, led by Shannon 'SUNSfan' Scotten (CEO) and Andrew 'Jenkins' Jenkins (CPO), has a team of experienced developers and creative talents dedicated to delivering a top-tier gaming experience.</h4>
            <h1>Support the Kickstarter</h1>
            <h4>The Kickstarter campaign for Relic Arena is now live. Gaming enthusiasts and the community are invited to support the team in bringing this unique vision to life. For more details, interested parties are encouraged to visit the Kickstarter page. Below are more links and assets related to this project.
                <br></br><br></br>
                Steam Page: <a href="https://store.steampowered.com/app/2654490/Relic_Arena">Relic Arena</a>
                <br></br>
                Official website: <a href="https://relicarena.com">https://relicarena.com</a>
                <br></br>
                Social media channels: <a href="https://relicarena.com/socials">Social</a>
                <br></br>
                Contact Information: <a href="mailto:contact@relicarena.com">Contact Email</a>
                <br></br>
                Assets: <a href="https://drive.google.com/drive/folders/1pNSSow77eRBL8v1nLqEcfzz3U5St_SQc?usp=sharing">Assets on Google Drive</a></h4>
            <img src={Battle} alt="Fight scene showing various Relics in use"></img>
            <br></br>
            A fight scene showing various Relics in use (PRE ALPHA FOOTAGE)
        </div>
    );
};

export default PressRelease;
