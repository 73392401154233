import React from 'react';
import './faq.css'

const Faq = () => {
    return (
        <div className='faq'>
            <h1>Meet the Team</h1>
            <h4><a href="https://liquipedia.net/dota2/SUNSfan">Shannon 'SUNSfan' Scotten</a>
            <br></br>
            CEO</h4>
            <h4><a href="https://liquipedia.net/dota2/Jenkins">Andrew 'Jenkins' Jenkins</a>
            <br></br>
            CPO</h4>
            <h4><a href="https://liquipedia.net/valorant/Sajedene">Nikki 'Sajedene' Elise</a>
            <br></br>
            COO
            </h4>
            <h4>Kirill 'DoctorGester' Artemov
            <br></br>
            Lead Developer</h4>
            <h4>Leonardo 'Nokdef' Vieira Alvarez
            <br></br>
            Visual Director</h4>
            <h4>Daneel 'Toyoka' Filimonov
            <br></br>
            Visual FX Artist</h4>
            <h4>Aleksandar 'Bukka' Bukurecki
            <br></br>
            UI/UX Artist</h4>
            <h4>Fabián 'efe' Urbina Ampuero
            <br></br>
            Programmer</h4>
            <h4>invalidnick
            <br></br>
            3D Level Designer</h4>
            <h4>Félix 'GusGussr' Pageau
            <br></br>
            Animator/Rigger</h4>
            <h4>Jeffrey 'pumodi' Brice
            <br></br>
            Music & Sound</h4>
            <h4>Willian 'will' Padilha de Freitas
            <br></br>
            3D Artist</h4>
            <h4>Jesus 'Mango' Ramos
            <br></br>
            2D Artist</h4>
            <h4>Ibrahem 'Noobovich' Swaid
            <br></br>
            2D Promo Artist</h4>
            <h4>Trung 'TrungTH' Ha Ta
            <br></br>
            2D Promo Artist</h4>
            <h4>Travis 'Quazi' Niedergerke
            <br></br>
            Video Editor</h4>
        </div>
    );
};

export default Faq;
