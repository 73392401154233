import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar.component';
import Footer from './components/footer.component';
import Video from './components/video.component';
import DiscordJoin from './components/discord-join.component';
import SteamWishlist from './components/steam-wishlist.component';
import Socials from './components/socials.component';
import Faq from './components/faq.component';
import Relics from './components/relics.component';
import PressRelease from './components/press-release.component';
import RelicsStats from './components/relics-stats.component';
import LeadersStats from './components/leaders-stats.component';
import ReportBug from './components/report-bug.component';
import Team from './components/team.component';
import './App.css';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={
                        <header className="App-header-home">
                            <Navbar />
                            <div className='home-content'>
                                <Video videoId="GQNP1pfHA70" />
                                <SteamWishlist />
                                <DiscordJoin />
                            </div>
                            <Footer />
                        </header>
                    } />
                    <Route path="/socials" element={
                        <header className="App-header-faq">
                            <Navbar />
                            <Socials />
                            <Footer />
                        </header>
                    } />
                    <Route path="/faq" element={
                        <header className="App-header-faq">
                            <Navbar />
                            <Faq />
                            <Footer />
                        </header>
                    } />
                    <Route path="/relics" element={
                        <header className="App-header">
                            <Navbar />
                            <RelicsStats />
                            <Footer />
                        </header>
                    } />
                    <Route path="/leaders" element={
                        <header className="App-header">
                            <Navbar />
                            <LeadersStats />
                            <Footer />
                        </header>
                    } />
                    <Route path="/map" element={
                        <header className="App-header">
                            <Navbar />
                            <Relics />
                            <Footer />
                        </header>
                    } />
                    <Route path="/report-bug" element={
                        <header className="App-header">
                            <Navbar />
                            <ReportBug />
                            <Footer />
                        </header>
                    } />
                    <Route path="/press" element={
                        <header className="App-header-faq">
                            <Navbar />
                            <PressRelease />
                            <Footer />
                        </header>
                    } />
                    <Route path="/team" element={
                        <header className="App-header-faq">
                            <Navbar />
                            <Team />
                            <Footer />
                        </header>
                    } />
                 </Routes>
            </Router>
        </div>
    );
}

export default App;
