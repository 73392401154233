import React from 'react';

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <a href="/press" style={linkStyle}>Press Release</a> |
            <a href="/team" style={linkStyle}>Meet the Team</a> |
            <a href="mailto:contact@relicarena.com" style={linkStyle}>contact@relicarena.com</a> |
            &nbsp; © Double Edge Studios 2023
        </footer>
    );
};

const footerStyle = {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(14, 14, 14, 0.8)',
    color: 'white',
    textAlign: 'center',
    padding: '10px 0'
};

const linkStyle = {
    color: 'white',
    margin: '0 10px'
};

export default Footer;
