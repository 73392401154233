import React from 'react';
import socialBanner from './images/social-banner-2.png';
import kickstarter from './images/social-kickstarter-back.png';
import steam from './images/social-steam-wishlist.png';
import reddit from './images/reddit-2.png';
import discord from './images/social-discord-join.png';
import relicArenaTitle from './images/relic-arena-title.png';
import relicArena from './images/profile_tablet_relic_arena.png';
import doubleEdgeTitle from './images/double-edge-games-title.png';
import doubleEdge from './images/profile_tablet_double_edge.png';
import sunsFanTitle from './images/sunsfan-title.png';
import sunsFan from './images/profile_tablet_sunsfan.png';
import jenkinsTitle from './images/jenkins-title.png';
import jenkins from './images/profile_tablet_jenkins.png';
import twitter from './images/twitter_plus1.png';
import facebook from './images/facebook_plus1.png';
import twitch from './images/twitch_plus1.png';
import youtube from './images/youtube_plus1.png';
import instagram from './images/instagram_plus1.png';
import threads from './images/threads_plus1.png';
import empty from './images/social_empty.png';
import reward1 from './images/social-reward-1.png';
import reward2 from './images/social-reward-2.png';
import './socials.css';

class Socials extends React.Component {
    render() {
        return (
            <div className='socials'>
                <div className='banner'>
                    <img src={socialBanner} alt="Social Banner" />
                </div>
                <div className='banner'>
                    <a href="https://www.kickstarter.com/projects/jenkins69/relic-arena"><img src={kickstarter} alt="Social Banner" /></a>
                </div>
                <div className='banner'>
                    <a href="https://store.steampowered.com/app/2654490/Relic_Arena"><img src={steam} alt="Social Banner" /></a>
                </div>
                <div className='banner'>
                    <a href="https://discord.gg/uVN64WHzxp"><img src={discord} alt="Social Banner" /></a>
                </div>
                <div className='banner'>
                    <a href="https://www.reddit.com/r/RelicArena/"><img src={reddit} alt="Social Banner" /></a>
                </div>
                <br></br>
                <div className='banner'>
                    <img src={relicArenaTitle} alt="Relic Arena" />
                </div>
                <div className='profile-and-icons'>
                    <div className='profile'>
                        <img src={relicArena} alt="Relic Arena" />
                    </div>
                    <div className='icons'>
                        <div className='icon'>
                            <a href="https://twitter.com/relicarena"><img src={twitter} alt="Twitter" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.youtube.com/channel/UCVWfEoA3-EIVi5gy3Rsibyg"><img src={youtube} alt="YouTube" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.instagram.com/relicarena"><img src={instagram} alt="Instagram" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.threads.net/@relicarena"><img src={threads} alt="Threads" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.facebook.com/relicarena"><img src={facebook} alt="Facebook" /></a>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className='banner'>
                    <img src={doubleEdgeTitle} alt="Double Edge" />
                </div>
                <div className='profile-and-icons'>
                    <div className='profile'>
                        <img src={doubleEdge} alt="Double Edge" />
                    </div>
                    <div className='icons'>
                        <div className='icon'>
                            <a href="https://twitter.com/doubleedgegames"><img src={twitter} alt="Twitter" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.instagram.com/doubleedgegames"><img src={instagram} alt="Instagram" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.threads.net/@doubleedgegames"><img src={threads} alt="Threads" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.facebook.com/doubleedgegames"><img src={facebook} alt="Facebook" /></a>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className='banner'>
                    <img src={sunsFanTitle} alt="SUNSfan" />
                </div>
                <div className='profile-and-icons'>
                    <div className='profile'>
                        <img src={sunsFan} alt="SUNSfan" />
                    </div>
                    <div className='icons'>
                        <div className='icon'>
                            <a href="https://twitter.com/sunsfantv"><img src={twitter} alt="Twitter" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.twitch.tv/sunsfantv"><img src={twitch} alt="Twitch" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.instagram.com/sunsfantv"><img src={instagram} alt="Instagram" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.threads.net/@sunsfantv"><img src={threads} alt="Threads" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.facebook.com/SUNSfanTV"><img src={facebook} alt="Facebook" /></a>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className='banner'>
                    <img src={jenkinsTitle} alt="Jenkins" />
                </div>
                <div className='profile-and-icons'>
                    <div className='profile'>
                        <img src={jenkins} alt="Jenkins" />
                    </div>
                    <div className='icons'>
                        <div className='icon'>
                            <a href="https://twitter.com/jenkinsdota"><img src={twitter} alt="Twitter" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.twitch.tv/420jenkins"><img src={twitch} alt="Twitch" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.youtube.com/@Jenkins69"><img src={youtube} alt="YouTube" /></a>
                        </div>
                        <div className='icon'>
                            <a href="https://www.instagram.com/jenkinsdota"><img src={instagram} alt="Instagram" /></a>
                        </div>
                    </div>
                </div>
                <div className='banner'>
                    <img src={reward1} alt="Social Reward" />
                </div>
                <div className='banner'>
                    <img src={reward2} alt="Social Reward" />
                </div>
          </div>
        );
    }
}

export default Socials;
